<template>
  <div class="page--crud_view">
    <FormBody
      :unikey="key"
      :structure="structure"
      :fields="setup.fields"
      :data="data"
      ref="form"
      :view="true"
      v-if="!load"
    />
    <div class="action-btn">
      <EvButton class="back-btn" @click="go(key)">{{ $t("button.back") }}</EvButton>
      <!-- <EvButton class="edit-btn" @click="go(`${key}/${$route.params.id}/edit`)">{{ $t("button.edit") }}</EvButton> -->
    </div>
  </div>
</template>

<script>
import FormBody from "@/components/modules/form/FormBody";
import { mapState } from 'vuex';

export default {
  components: {
    FormBody
  },
  async created() {
    this.key = this.$route.params.key;
    Object.assign(this.setup, this.$setting.crud[this.key]);
    this.structure = this.setup.structure.view || this.setup.structure.list.map(field=>[field]);
    this.setup.fields = {
      createdDt: { type: "datetime" },
      modifiedDt: { type: "datetime" },
      deletedDt: { type: "datetime" },
      createdBy: { type: "object", props: { label: "login_name", value: "id" } },
      modifiedBy: { type: "object", props: { label: "login_name", value: "id" } },
      deletedBy: { type: "object", props: { label: "login_name", value: "id" } },
      ...this.setup.fields,
    }
  },
  async beforeMount() {
    this.load = true;
    this.needLoading(true);
    try{
      this.data = (await this.$axios(`/${this.key}/${this.$route.params.id}`)).data;
    }catch(err) {
      this.$notify({
        // title: ,
        msg: `[${err.response.status}] ${this.$t("error.generic")}`,
        type: "error"
      });
    };
    this.load = false;
    this.$forceUpdate();
    await new Promise((resolve,reject)=>{
      setTimeout(()=>resolve(), 200);
    });
    console.log("data retrieve");
    this.needLoading(false);
  },
  data() {
    return {
      load: false,
      setup: {},
      data: {},
      structure: []
    }
  }
}
</script>

<style lang="scss">
.page--crud_view{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  .widget--form_form_body{
    .field{
      &>.value{
        background: #ddd;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 3px;
      }
      &.type--component{
        margin: 10px 0;
        & > .label{
          display: none;
        }
        & > .value{
          padding: 0;
          background: transparent;
        }
      }
      &.type--status{
        & > .value{
          background: transparent;
        }
      }
    }
  }
  & > .action-btn{
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }
}
</style>